<div class="my-login-page">
	<section class="h-100">
		<div class="container h-100">
			<div class="row justify-content-md-center h-100">
				<div class="card-wrapper">
					<div class="d-flex justify-content-center">
						<img style=" border-radius: 8px; margin-top:50px;" src="../../assets/img/logo.png" alt="logo">
					</div>
					<div class="card fat" style="margin-top:10px">
						<div class="card-body">
							<h1 class="card-title d-flex justify-content-center" style="color:#273746">WiFi User Registeration</h1>


              <form  style="color: white;" [formGroup]="service.Registerform" autocomplete="off" (submit)="onSubmit()">
                <div class="row">
                  <div class="form-group col-md-8">
                    <label style="color: black;">FullName</label>
                    <input class="form-control" formControlName="FullName">
                    <label class="text-danger"
                      *ngIf="service.Registerform.get('FullName').touched && service.Registerform.get('FullName').errors?.required">This
                      field is mandatory.</label>
                  </div>

                  <div class="form-group col-md-4">
                    <label style="color: black;">Age Group</label>
                    <select class="form-select"  formControlName="ageGroup">
                      <option selected>Select</option>
                      <option *ngFor="let info of age">
                        {{info}}
                      </option>
                    </select>
                    <label class="text-danger"
                      *ngIf="service.Registerform.get('ageGroup').touched && service.Registerform.get('ageGroup').errors?.required">This
                      field is mandatory.</label>
                  </div>

                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <label style="color: black;">Email</label>
                    <input class="form-control" formControlName="Email">
                    <label class="text-danger"
                      *ngIf="service.Registerform.get('Email').touched && service.Registerform.get('Email').errors?.required">This
                      field is mandatory.</label>
                  </div>
                  
                  <div class="form-group col-md-3">
                    <label style="color: black;">Gender</label>
                    <select class="form-select"  formControlName="Gender">
                      <option selected>Select</option>
                      <option *ngFor="let info of genders">
                        {{info.gender}}
                      </option>
                    </select>
                    <label class="text-danger"
                      *ngIf="service.Registerform.get('Gender').touched && service.Registerform.get('Gender').errors?.required">This
                      field is mandatory.</label>
                  </div>

                  <div class="form-group col-md-3">
                    <label style="color: black;">Race</label>
                    <select class="form-select"  formControlName="Race">
                      <option selected>Select</option>
                      <option *ngFor="let info of races">
                        {{info.race}}
                      </option>
                    </select>
                    <label class="text-danger"
                      *ngIf="service.Registerform.get('Race').touched && service.Registerform.get('Race').errors?.required">This
                      field is mandatory.</label>
                  </div>

                  
                </div>


                 <hr>
                <div class="text-center">
                  <div class="form-group col-md-8 offset-md-2">
                     <button type="button" class="btn btn-lg btn-success" style="margin:10px;" (click)="login()" >Access WiFi</button>
                  </div>
                </div>
              </form>

						</div>
					</div>
					<div class="footer">
            Copyright &copy; 2020 &mdash; Brace Holdings Innovations
            <div class="d-flex justify-content-center">
              <img style=" border-radius: 8px; margin-top:50px;" src="../../assets/img/blg.png" alt="logo">
            </div>
					</div>
				</div>
			</div>
		</div>
	</section>

</div>
