import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service' ;
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  genders ;
  races ;
  age = ['Below 12','12-17','17-25','26-31','Above 31'] ;


  constructor(public service:DataService,public router: Router) { }

  ngOnInit(): void {
    this.service.Registerform.reset();

    this.service.Genders().subscribe(
      res => {
        this.genders = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Races().subscribe(
      res => {
        this.races = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    
  }

  onSubmit() {
    this.service.Register().subscribe(
      (res: any) => {
        window.location.href = 'https://www.hsportal.braceinnovations.co.za/';
      }
    );
  }

  login(){
    window.location.href = 'https://www.hsportal.braceinnovations.co.za/';
  }


}
